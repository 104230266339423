/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Constants } from '../../constants';
import { Typography } from '../../constants/typography';
import Carousel from '../carousel';

const Fact = ({ stat }) => (
  <div className="flex flex-col items-center">
    <span className={`${Typography.h2} tp:text-[72px] sd:text-[72px] block ${Typography.strokedWhite}`}>
      <span>{stat.number.prependText}</span>
      <span>{stat.number.number}</span>
      <span>{stat.number.appendText}</span>
    </span>
    <span className={`${Typography.body1} mt-5`}>{stat.title}</span>
  </div>
);

const BrandFacts = ({ content }) => {
  const [statGroups, setStatGroups] = useState(null);
  useEffect(() => {
    const statArray = [...content.facts];
    if (!statGroups && statArray && statArray.length) {
      const sg = [];
      while (statArray.length > 0) {
        sg.push(statArray.splice(0, 2));
      }
      setStatGroups(sg);
    }
  }, [content, statGroups]);
  return (
    <div
      id="brand-facts"
      className={`
        ${Constants.panelBase}
        bg-charcoal light-header header-section
        flex flex-col items-center justify-center
        text-white
      `}
    >
      <div className="flex flex-col items-center justify-end pb-4 lg:pb-6 lg:justify-center w-full lg:pt-0">
        <h3
          className={`
            ${Typography.h3} 
            w-full 
            mb-10 lg:mb-2 
            px-8 sd:lg:px-0
            text-center 
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="10%"
          data-scroll-repeat="true"
          dangerouslySetInnerHTML={{ __html: content.title }}
        />
        <div
          className={`
            hidden md:flex justify-between 
            mt-16 
            w-full max-w-[1142px] 
            tp:px-12
            sd:px-8
            tl:px-16
            ${Constants.fadeUp}
          `}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="10%"
          data-scroll-repeat="true"
        >
          {
            statGroups && statGroups.map((group) => (
              <div key={`brand-stats-desktop-${group[0].title}`} className="flex flex-col space-y-18">
                { group.map((stat) => <Fact stat={stat} key={stat.title} />)}
              </div>
            ))
          }
        </div>
        {
          statGroups && (
            <Carousel activeColor="after:bg-white" navPosition="mb-0 items-center justify-center" inactiveColor="after:bg-gray1 after:opacity-50" className="md:hidden">
              {
                statGroups.map((group) => (
                  <div key={`brand-stats-mobile-${group[0].title}`} className="flex flex-col space-y-14">
                    { group.map((stat) => <Fact stat={stat} key={stat.title} />) }
                  </div>
                ))
              }
            </Carousel>
          )
        }
      </div>
    </div>
  );
};

export default BrandFacts;
