/* eslint-disable no-lonely-if */
import gsap, { SplitText } from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { Typography } from '../../constants/typography';
import {
  selectIsLandscape,
  selectIsMobile,
  selectIsTablet,
  selectScrollReady,
  setHeaderColorOverride,
  setHideHeader,
} from '../../state/reducers/utils';
import ScrollButton from '../buttons/scroll';
import SkewRotateAsterisk from '../icons/skew-rotate-asterisk';

gsap.registerPlugin(SplitText);

const BrandsHero = ({ content }) => {
  const dispatch = useDispatch();
  const currentIndex = useRef(0);
  const scrollY = useRef(0);
  const isLandscape = useSelector(selectIsLandscape);
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const [current, setCurrent] = useState(0);
  const [maxHeight, setMaxHeight] = useState(null);
  const [stickyProps, setStickyProps] = useState({});
  const panel1 = useRef(null);
  const panel2 = useRef(null);
  const tl = useRef(null);
  const scrollReady = useSelector(selectScrollReady);
  const animationComplete = () => {
    if (isMobile || isTablet) return;
    setMaxHeight(scrollY.current + window.innerHeight);
    dispatch(setHideHeader(true));
  };

  useEffect(() => {
    if (isMobile === null || isTablet === null) return;
    if (!isMobile && !isTablet) {
      setStickyProps({
        'data-scroll': true,
        'data-scroll-sticky': !isTablet && !isMobile,
        'data-scroll-target': '#brands-hero',
      });
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (window.locoScroll) {
      window.locoScroll.update();
      window.locoScroll.start();
    }
    if (!maxHeight) {
      dispatch(setHideHeader(false));
    }
  }, [maxHeight]);

  useEffect(() => {
    if (isMobile === null || isTablet === null || tl.current) return;
    dispatch(setHeaderColorOverride('text-charcoal'));

    tl.current = gsap.timeline({ onComplete: animationComplete });
    const firstSplit = new SplitText(panel1.current.querySelector('h1'), { type: 'words' });
    const secondSplit = new SplitText(panel2.current.querySelector('h2'), { type: 'words' });
    const baseDelay = 0.4;
    tl.current.from(firstSplit.words, {
      duration: 0.8, y: 50, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
    }, baseDelay);
    if (isMobile || isTablet) {
      tl.current.from(panel1.current.querySelector('.scroll-button'), {
        duration: 0.8, y: 100, alpha: 0, ease: 'expo.inOut',
      }, '-=0.5');
    }
    tl.current.addLabel('endIn');
    if (!isMobile && !isTablet) {
      tl.current.to(firstSplit.words, {
        duration: 0.8, y: -50, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
      }, '-=0');
    }
    tl.current.from(secondSplit.words, {
      duration: 0.8, y: 50, stagger: 0.05, ease: 'expo.inOut',
    }, isMobile || isTablet ? '-=0' : '-=0.7');
    tl.current.from(secondSplit.words, {
      duration: 0.8, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
    }, '-=0.8');
    tl.current.from(panel2.current.querySelector('p'), {
      duration: 0.6, y: 50, alpha: 0, ease: 'expo.out',
    }, '-=0.6');
    if (isMobile || isTablet) {
      tl.current.from(panel2.current.querySelector('.scroll-button'), {
        duration: 0.8, y: 100, alpha: 0, ease: 'expo.inOut',
      }, '-=0.5');
    }
    tl.current.addLabel('endOut');
    tl.current.pause();
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (isMobile === null || isTablet === null) return;
    if (current === 0) {
      setMaxHeight(null);
      tl.current.tweenTo('endIn');
    } else if (current === 1) {
      tl.current.tweenTo('endOut');
    }
  }, [current, isMobile, isTablet]);

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.start();
      window.locoScroll.on('scroll', (e) => {
        scrollY.current = e.scroll.y;
        if (e.scroll.y > window.innerHeight * (isMobile || isTablet ? 0.45 : 0.25)) {
          if (currentIndex.current !== 1) {
            setCurrent(1);
            currentIndex.current = 1;
            window.locoScroll.stop();
          }
        } else { 
          if (currentIndex.current !== 0) {
            setCurrent(0);
            currentIndex.current = 0;
            tl.current.tweenTo('endIn');
          }  
        }
      });
    }
  });

  return (
    <div className="bg-tan dark-header header-section relative overflow-hidden tl:h-auto tp:h-auto lg:h-[500vh]" style={{ maxHeight }} id="brands-hero">
      <div
        className={`
          ${!isTablet && 'lg:!absolute'}
          top-0
          left-0
          ${Constants.panelBase}
          sm:responsive-h-screen
          tp:responsive-h-screen
          tl:responsive-h-screen
          dark-header header-section
          flex items-end justify-start 
          px-6 pb-32 tp:pb-32 sd:pb-16 lg:p-16 
          z-10
        `}
        ref={panel1}
        {...stickyProps}
      >
        <h1
          className={`
            z-30
            w-full
            ${Typography.h1} 
            ${Typography.strokedCharcoal} 
            text-left max-w-[830px]
            sd:max-w-[80%]
          `}
        >
          {content.outlineText}
        </h1>
        {
          (isMobile || isTablet) && (
            <div
              className={`scroll-button ${isTablet && isLandscape ? Constants.stickyScrollButton : Constants.stickyScrollButtonMobile}`}
            >
              <ScrollButton
                location="brands"
                arrowNo={current+1}
                dynamic
                positionClassName="relative"
                scrollTo="smarter-ad-space"
              />
            </div>
          )
        }
      </div>
      <div
        className={`
          ${!isTablet && 'lg:!absolute'}
          top-0
          left-0
          ${Constants.panelBase}
          sm:responsive-h-screen
          tp:responsive-h-screen
          tl:responsive-h-screen
          dark-header header-section
          flex items-end justify-start 
          px-6 pb-32 tp:pb-32 sd:pb-16 lg:p-16 
          z-10
        `}
        {...stickyProps}
      >
        <div
          className="w-full h-full right-16 left-16 flex flex-col justify-end"
          ref={panel2}
        >
          <h2 className={`${Typography.h1} text-charcoal text-left max-w-[1300px]`}>
            {content.fillText}
          </h2>
          <p className={`mt-8 lg:mt-16 ${Typography.body1} text-charcoal max-w-[750px] tl:max-w-[750px]`}>
            {content.bodyText}
          </p>
        </div>
        {
          (isMobile || isTablet) && (
            <div
              className={`scroll-button ${isTablet && isLandscape ? Constants.stickyScrollButton : Constants.stickyScrollButtonMobile}`}
            >
              <ScrollButton
                location="brands"
                arrowNo={current+1}
                dynamic
                positionClassName="relative"
                scrollTo="smarter-ad-space"
              />
            </div>
          )
        }
      </div>
      <div className="fixed tl:fixed tp:fixed lg:absolute top-0 left-0 z-0 w-full h-screen sm:responsive-h-screen" data-scroll data-scroll-sticky data-scroll-target="#brands-hero">
        <SkewRotateAsterisk />
      </div>
      {
        !isMobile && !isTablet && (
          <div
            className={Constants.stickyScrollButton}
            {...stickyProps}
          >
            <ScrollButton
              location="brands"
              arrowNo={current+1}
              dynamic
              positionClassName="relative"
              scrollTo="smarter-ad-space"
            />
          </div>
        )
      }
    </div>
  );
};

export default BrandsHero;
