import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Constants } from '../../constants';
import { sendTagEvent } from '../../constants/google-tags';
import { Typography } from '../../constants/typography';
import { toggleForm } from '../../state/reducers/utils';
import PrimaryButton from '../buttons/primary';

const BrandsOnBoard = ({ content }) => {
  const dispatch = useDispatch();
  const { title, brands, buttonA } = content;
  return (
    <div
      id="brands-on-board"
      className={`
        ${Constants.panelBase}
        bg-tan dark-header header-section
        px-8 pt-24 pb-24 lg:px-16 lg:py-36
        flex flex-col items-center justify-center
      `}
    >
      <h3
        className={`
          ${Typography.h3Alt}
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="10%"
        data-scroll-repeat="true"
      >
        {title}
      </h3>
      <div className="flex flex-1 lg:space-y-0 lg:mb-0 justify-center lg:justify-between items-center flex-wrap max-w-[1300px]">
        <div className="sm:max-h-[450px] h-full flex flex-wrap justify-center lg:justify-between items-between sd:items-center lg:items-center">
          {brands.map((brand) => (
            <div
              key={brand.image.localFile.id}
              className={`
                ${brand.class} 
                w-1/2
                px-4
                tp:w-1/2
                lg:w-1/3
                sd:w-1/3
                flex
                h-[33.33%]
                justify-center 
                items-center
                tl:w-48
                lg:h-[110px] 
                ${Constants.fadeUp}
              `}
              data-scroll
              data-scroll-class="fade-up-visible"
              data-scroll-offset="10%"
              data-scroll-repeat="true"
            >
              <div className="w-36 sd:w-44 tp:w-60 lg:w-60 ">
                <GatsbyImage
                  image={getImage(brand.image.localFile)}
                  alt="Brand Logo"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <PrimaryButton
        className={`
          ${Constants.fadeUp}
          flex-0
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="10%"
        data-scroll-repeat="true"
        onClick={() => {
          sendTagEvent('click', {
            event_category: 'Brands',
            event_label: 'Advertise with us',
          });
          dispatch(toggleForm(true, Constants.BRANDS));
        }}
      >
        {buttonA.label}
      </PrimaryButton>
    </div>
  );
};

export default BrandsOnBoard;
