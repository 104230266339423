/* eslint-disable no-console */
import React from 'react';
import { graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import SEO from '../components/seo';
import Layout from '../components/app-layout';
import BrandsHero from '../components/brands/hero';
import ValueProps from '../components/value-props';
import BrandsOnBoard from '../components/brands/brands-on-board';
import PartnersDiversity from '../components/partners-diversity';
import BrandFacts from '../components/brands/facts';
import { Constants } from '../constants';
import { toggleForm } from '../state/reducers/utils';
import { sendTagEvent } from '../constants/google-tags';

const BrandsPage = ({ data }) => {
  const dispatch = useDispatch();
  const { brands } = data.brands;
  const {
    brandsA, brandsB, brandsC, brandsD, brandsE, brandsF,
  } = brands;
  return (
    <Layout defaultHeaderColor="text-charcoal" footerColor="bg-white">
      <SEO title={data.brands.title} />
      <div className="w-full h-full" data-scroll-section>
        <BrandsHero
          content={{
            outlineText: brandsA.title,
            fillText: brandsB.title,
            bodyText: brandsB.paragraph,
          }}
        />
        <ValueProps
          id="smarter-ad-space"
          content={{
            title: brandsC.title,
            subtitle: brandsC.paragraph,
            items: brandsC.icons,
            buttonA: brandsC.buttonA,
          }}
          carouselConfig={{
            inactiveColor: 'after:bg-gray1 after:opacity-50',
          }}
          primaryButtonProps={{
            onClick: () => {
              sendTagEvent('click', {
                event_category: 'Brands',
                event_label: 'Connect with us',
              });
              dispatch(toggleForm(true, Constants.BRANDS));
            },
          }}
        />
        <BrandFacts
          content={{
            title: `${brandsD.titleA}<br/>${brandsD.titleB}`,
            facts: brandsD.facts,
          }}
        />
        <PartnersDiversity content={brandsE} type="brands" />
        <BrandsOnBoard content={brandsF} />
      </div>
    </Layout>
  );
};

export default BrandsPage;

export const brandsData = graphql`
  {
    brands: wpPage(databaseId: {eq: 251}) {
      title
      brands {
        brandsA {
          title
        }
        brandsB {
          title
          paragraph
        }
        brandsC {
          title
          paragraph
          icons {
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          buttonA {
            label
            link
          }
        }
        brandsD {
          titleA
          titleB
          facts {
            title
            number {
              prependText
              number
              appendText
            }
          }
        }
        brandsE {
          titleFill
          titleOutline
          creatives {
            name
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          scrollingBanner {
            title
          }
        }
        brandsF {
          title
          brands {
            image {
              localFile {
                id
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          buttonA {
            label
            link
          }
        }
      }
    }
  }
`;
